document.documentElement.className = "js";

// ELEMENTS
var navs = document.querySelectorAll("nav.navigation");
var submenus = document.querySelectorAll("li.submenu");
var billingForm = document.querySelector(".signup__form");

// NAVIGATION
if (navs.length) {
  navs.forEach(function(nav, index) {
    var navToggler = nav.querySelector(".navigation__toggler");
    var navMenu = nav.querySelector("ul.navigation__list");

    navToggler.addEventListener("click", function() {
      if (navMenu.getAttribute("data-menu") == "") {
        navMenu.setAttribute("data-menu", "open");
      } else {
        navMenu.setAttribute("data-menu", "");

        // close all submenus
        var submenus = nav.querySelectorAll("li.submenu");
        submenus.forEach(function(submenu, index) {
          submenu.setAttribute("data-submenu", "");
        });
      }
    });
  });
}

// NAVIGATION SUBMENUS
if (submenus.length) {
  submenus.forEach(function(submenu, index) {
    var submenuToggler = submenu.querySelector(".submenu__toggler");

    submenuToggler.addEventListener("click", function() {
      if (submenu.getAttribute("data-submenu") == "") {
        submenu.setAttribute("data-submenu", "open");
      } else {
        submenu.setAttribute("data-submenu", "");
      }
    });
  });
}

// BILLING DETAILS
if (billingForm) {
  var billingDataToggle = document.getElementById("company-checkbox");
  var personalDetails = billingForm.querySelector(".group__personal-details");
  var companyDetails = billingForm.querySelector(".group__company-details");
  if (billingDataToggle && personalDetails && companyDetails) {
    billingDataToggle.addEventListener("change", function() {
      if (billingDataToggle.checked == true) {
        personalDetails.classList.add("inactive");
        companyDetails.classList.add("active");
      } else {
        personalDetails.classList.remove("inactive");
        companyDetails.classList.remove("active");
      }
    });
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const btnOrders = document.querySelectorAll('.books .btn-book-order-trigger');
  const orderModal = document.querySelector('#book-popup');
  const btnOrderModalClose = document.querySelector('#book-popup .popup-body__close');

  if (btnOrders) {
    btnOrders.forEach(button => {
      button.addEventListener('click', () => {
        if (orderModal) {
          const bookID = button.dataset.bookId;
          const bookTitle = button.dataset.bookTitle;
          const bookStock = button.dataset.bookStock;
          const bookPrice = button.dataset.bookPrice;

          const titleElem = orderModal.querySelector('p.title');
          if (titleElem) {
            titleElem.innerHTML = bookTitle;
          }

          const quantityElem = orderModal.querySelector('input[type="number"]');
          if (quantityElem) {
            quantityElem.setAttribute('max', bookStock);
            quantityElem.dataset.price = bookPrice;
          }

          const bookIdElem = orderModal.querySelector('input[name="book_id"]');
          if (bookIdElem) {
            bookIdElem.setAttribute('value', bookID);
          }

          orderModal.classList.add('is-active');
        }
      });
    });
  }

  if (btnOrderModalClose) {
    btnOrderModalClose.addEventListener('click', () => {
      if (orderModal) {
        orderModal.classList.remove('is-active');
      }
    });
  }

  const quantityInput = document.querySelector('#book-popup input[name="quantity"]');
  if (quantityInput && orderModal) {
    quantityInput.addEventListener('keyup', () => {
      const priceInput = orderModal.querySelector('input[name="price"]');
      if (priceInput) {
        const price = quantityInput.value * quantityInput.dataset.price;
        priceInput.value = price;
        priceInput.previousElementSibling.innerHTML = 'Price: ' + price + ' EUR';
      }
    });
  }
});
